
import { Vue, Component } from "vue-property-decorator";
import { mapState } from "vuex";
import { K12School } from "@/models/interface";
import InfoModal from "@/components/InfoModal.vue";
import { drawPieChart, PieChartColors } from "@/assets/js/pie-chart";
import { drawLineChart } from "@/assets/js/line-chart";
@Component({
  components: { InfoModal },
  computed: { ...mapState("app", ["school", "mobileView", "tabletView"]) },
  metaInfo: { title: "Spending Page" },
})
export default class SchoolDistrictSpending extends Vue {
  givenTitle = "";
  givenDescription = "";
  
  schoolNameAverage = "";
  schoolNameAveragePercentage = "";
  totalSchoolAmount = 0;
  
  schoolSystemAverage = "";
  schoolSystemAveragePercentage = "";
  totalSchoolSystemAmount = 0;

  stateAverage = "";
  stateAveragePercentage = "";
  totalStateAmount = 0;
    
  mobileView!: boolean;
  tabletView!: boolean;
  school!: K12School;
  showItemDetail = false;
  dataList = [];
  public pielabelObj: string[] = [];
  public PieDataObj: (number | string)[] = [];
  PieDataNaNCount = 0;
  public LinelabelObj: string[] = [];
  public LineDataObj: number[] = [];
  pieChartColors = PieChartColors;
  selectedHeaderText = "";

  mounted() {
    this.generatepiechart();
    this.generatelinechart();

    this.schoolSpending.schoolSpendingByTypeBarChartData.forEach(
        (element) => {
          this.totalSchoolAmount = this.totalSchoolAmount + (isNaN(Number(element.schoolNameAverage)) ? 0: Number(element.schoolNameAverage));
          this.totalSchoolSystemAmount = this.totalSchoolSystemAmount + (isNaN(Number(element.schoolSystemAverage)) ? 0: Number(element.schoolSystemAverage));
          this.totalStateAmount = this.totalStateAmount + (isNaN(Number(element.stateAverage)) ? 0: Number(element.stateAverage));
        }
      );
  }

  get schoolSpending() {
    console.log(this.school.schoolSpending);
    return this.school && this.school.schoolSpending;
  }
  get studentExpenditure() {
    return (
      this.school &&
      this.school.schoolSpending &&
      this.school.schoolSpending.studentExpenditure
    );
  }

  showInfo(showModal: string) {
    switch (showModal) {
      case "AnnualSpendingPerStudent":
        (this.givenTitle = "Annual Spending Per Student"),
          (this.givenDescription =
            "The average amount of money spent on each student enrolled at a school. It reflects the amount of money a school receives, as well as the needs of its student population. Visit the “Breakdown by Student Groups” section for more information on the characteristics of students enrolled in this school.");
        break;
      case "FundingSources":
        (this.givenTitle = "Funding Sources"),
          (this.givenDescription =
            "<div>A school budget comprises money from various sources, including money from the local school system, the state and the federal government.</div>" +
            "<br><div><b>School Level Spending</b></div>" +
            "<div>School level spending are dollars spent specifically to benefit students at the school. These include items such as staff salaries and benefits, supplies, furniture, and utilities.</div>" +
            "<br><div><b>School Share of Central Office Spending</b></div>" +
            "<div>School system-level expenses are dollars spent for the benefit of all students in a school system. These include items such as transportation, central office operations, technology operations, maintenance, and property insurance.</div>" +
            "<br><div><b>State/Local Funds</b></div>" +
            "<div>This refers to dollars spent from state and local funding sources, which includes taxes, state school finance formula funds, and other state grants.</div>" +
            "<br><div><b>Federal Funds</b></div>" +
            "<div>This refers to dollars spent from federal funding sources, such as Title programs, special education programs, Medicaid or rehabilitation programs, and other federal grants.</div>");
        break;
      case "SpendingComparison":
        (this.givenTitle = "Spending Comparison"),
          (this.givenDescription =
            "This shows how a school system’s per student spending compares to the per student spending of the school system and state.");
        break;
      case "SpendingOverTime":
        (this.givenTitle = "Spending Over Time"),
          (this.givenDescription =
            "This shows how per student spending has changed over time at the school, school system, and state levels.");
        break;
      case "SpendingCategories":
        (this.givenTitle = "Spending Categories"),
          (this.givenDescription =
            "<div>This shows the breakdown of per student spending by certain spending categories.</div>" +
            "<br><div><b>Instruction</b></div>" +
            "<div>Activities directly related to the interaction between teachers and students, including salaries, employee benefits, purchased professional and technical services, instructional materials and supplies, and instructional equipment.</div>" +
            "<br><div><b>Student and Instructional Support</b></div>" +
            "<div>Pupil support activities designed to assess and improve the well-being of students and to supplement the teaching process, as well as instructional staff activities associated with assisting the instructional staff with content and the process of providing learning experiences for students.</div>" +
            "<br><div><b>School Administration</b></div>" +
            "<div>Activities concerned with the overall administrative responsibility for a school.</div>" +
            "<br><div><b>Transportation</b></div>" +
            "<div>Activities concerned with conveying students to and from school, as provided by state and federal laws.</div>" +
            "<br><div><b>Other Support</b></div>" +
            "<div>All activities not elsewhere identified that are required to establish, operate, support, and administer a school or school system.</div>");
        break;
      default:
        (this.givenTitle = ""), (this.givenDescription = "");
        break;
    }

    //this.$bvModal.show("informationModal");
  }

  setSpendingSideBarDetails(showModal: string) {
    this.givenTitle = showModal;
    const data = this.schoolSpending.schoolSpendingByTypeBarChartData.find((t) => t.spendingTypeName == showModal);

    this.schoolNameAverage = isNaN(Number(data?.schoolNameAverage)) ? "": (data?.schoolNameAverage != undefined ? data?.schoolNameAverage : "");
    this.schoolNameAveragePercentage = isNaN(Number(data?.schoolNameAverage)/this.totalSchoolAmount) ? "": String(100 * Number(data?.schoolNameAverage)/this.totalSchoolAmount);

    this.schoolSystemAverage = isNaN(Number(data?.schoolSystemAverage)) ? "": (data?.schoolSystemAverage != undefined ? data?.schoolSystemAverage : "");
    this.schoolSystemAveragePercentage = isNaN(Number(data?.schoolSystemAverage)/this.totalSchoolSystemAmount) ? "": String(100 * Number(data?.schoolSystemAverage)/this.totalSchoolSystemAmount);

    this.stateAverage = isNaN(Number(data?.stateAverage)) ? "": (data?.stateAverage != undefined ? data?.stateAverage : "");
    this.stateAveragePercentage = isNaN(Number(data?.stateAverage)/this.totalStateAmount) ? "": String(100 * Number(data?.stateAverage)/this.totalStateAmount);
  }

  get schoolLevelSpending() {
    if (!this.schoolSpending) return [];
    return [
      {
        id: 1,
        type: "State/Local",
        amount: this.schoolSpending.siteExpenditureState,
        percent: ""
      },
      {
        id: 2,
        type: "Federal",
        amount: this.schoolSpending.siteExpenditureFederal,
        percent: ""
      },
      {
        id: 3,
        type: "Total",
        amount: this.schoolSpending.siteExpenditureTotal,
        percent: this.schoolSpending.siteExpenditureTotalPercent
      },
    ];
  }

  get schoolSpendingCompare() {
    if (!this.schoolSpending) return [];
    return [
      {
        id: 1,
        type: "School System",
        amount: this.schoolSpending.schoolSpendingOverTime[0].schoolSystem,
      },
      {
        id: 2,
        type: "State",
        amount: this.schoolSpending.schoolSpendingOverTime[0].stateResult,
      },
    ];
  }

  get schoolSpendingChangeOvertime() {
    if (!this.schoolSpending) return [];
    return [
      {
        id: 1,
        type: "",
        yearI: this.schoolSpending.schoolSpendingOverTime[2].year,
        yearII: this.schoolSpending.schoolSpendingOverTime[1].year,
        yearIII: this.schoolSpending.schoolSpendingOverTime[0].year,
      },
      {
        id: 2,
        type: "School System",
        amountI: this.schoolSpending.schoolSpendingOverTime[2].schoolSystem,
        amountII: this.schoolSpending.schoolSpendingOverTime[1].schoolSystem,
        amountIII: this.schoolSpending.schoolSpendingOverTime[0].schoolSystem,
      },
      {
        id: 3,
        type: "State",
        amountI: this.schoolSpending.schoolSpendingOverTime[2].stateResult,
        amountII: this.schoolSpending.schoolSpendingOverTime[1].stateResult,
        amountIII: this.schoolSpending.schoolSpendingOverTime[0].stateResult,
      },
    ];
  }

  get centralOfficeSpending() {
    if (!this.schoolSpending) return [];
    return [
      {
        id: 1,
        type: "State/Local",
        amount: this.schoolSpending.centralExpenditureState,
        percent: ""
      },
      {
        id: 2,
        type: "Federal",
        amount: this.schoolSpending.centralExpenditureFederal,
        percent: ""
      },
      {
        id: 3,
        type: "Total",
        amount: this.schoolSpending.centralExpenditureTotal,
        percent: this.schoolSpending.centralExpenditureTotalPercent
      },
      {
        id: 4,
        type: "Per Student Spending",
        amount: this.school.schoolSpending.studentExpenditure,
        percent: "100%"
      },
    ];
  }

  generatepiechart() {
    this.PieDataNaNCount = 0;
    const Pielabels = [] as any;
    const PieData = [] as any;

    Pielabels.push("Instruction");
    if (
      this.schoolSpending.spendingInstruction == "" ||
      isNaN(+this.schoolSpending.spendingInstruction)
    ) {
      this.PieDataNaNCount++;
      if (this.schoolSpending.spendingInstruction == "") {
        this.PieDataObj.push("N/A");
      } else {
        this.PieDataObj.push(this.schoolSpending.spendingInstruction);
      }
    } else {
      PieData.push(Number(this.schoolSpending.spendingInstruction));
      this.PieDataObj.push(Number(this.schoolSpending.spendingInstruction));
    }
    Pielabels.push("Student and Instructional Support");
    if (
      this.schoolSpending.spendingPupilInstructionalSupport == "" ||
      isNaN(+this.schoolSpending.spendingPupilInstructionalSupport)
    ) {
      this.PieDataNaNCount++;
      if (this.schoolSpending.spendingPupilInstructionalSupport == "") {
        this.PieDataObj.push("N/A");
      } else {
        this.PieDataObj.push(
          this.schoolSpending.spendingPupilInstructionalSupport
        );
      }
    } else {
      PieData.push(
        Number(this.schoolSpending.spendingPupilInstructionalSupport)
      );
      this.PieDataObj.push(
        Number(this.schoolSpending.spendingPupilInstructionalSupport)
      );
    }
    Pielabels.push("School Administration");
    if (
      this.schoolSpending.spendingAdministration == "" ||
      isNaN(+this.schoolSpending.spendingAdministration)
    ) {
      this.PieDataNaNCount++;
      if (this.schoolSpending.spendingAdministration == "") {
        this.PieDataObj.push("N/A");
      } else {
        this.PieDataObj.push(this.schoolSpending.spendingAdministration);
      }
    } else {
      PieData.push(Number(this.schoolSpending.spendingAdministration));
      this.PieDataObj.push(Number(this.schoolSpending.spendingAdministration));
    }
    Pielabels.push("Transportation");
    if (
      this.schoolSpending.spendingTransportation == "" ||
      isNaN(+this.schoolSpending.spendingTransportation)
    ) {
      this.PieDataNaNCount++;
      if (this.schoolSpending.spendingTransportation == "") {
        this.PieDataObj.push("N/A");
      } else {
        this.PieDataObj.push(this.schoolSpending.spendingTransportation);
      }
    } else {
      PieData.push(Number(this.schoolSpending.spendingTransportation));
      this.PieDataObj.push(Number(this.schoolSpending.spendingTransportation));
    }
    Pielabels.push("Other Support");
    if (
      this.schoolSpending.otherSupport == "" ||
      isNaN(+this.schoolSpending.otherSupport)
    ) {
      this.PieDataNaNCount++;
      if (this.schoolSpending.otherSupport == "") {
        this.PieDataObj.push("N/A");
      } else {
        this.PieDataObj.push(this.schoolSpending.otherSupport);
      }
    } else {
      PieData.push(Number(this.schoolSpending.otherSupport));
      this.PieDataObj.push(Number(this.schoolSpending.otherSupport));
    }
    this.pielabelObj = Pielabels;
    if (this.PieDataNaNCount != 5) {
      drawPieChart("pieChart", Pielabels, PieData);
    }
  }

  generatelinechart() {
    const Linelabels: any = [];
    const LineDatas: any = [];
    const finalArr: any[] = [];
    Linelabels.push(this.schoolSpendingChangeOvertime[0].yearI);
    Linelabels.push(this.schoolSpendingChangeOvertime[0].yearII);
    Linelabels.push(this.schoolSpendingChangeOvertime[0].yearIII);

    if (
      !(
        this.schoolSpendingChangeOvertime[1].amountI == "" &&
        this.schoolSpendingChangeOvertime[1].amountII == "" &&
        this.schoolSpendingChangeOvertime[1].amountIII == ""
      )
    ) {
      LineDatas.push({
        label: "School System",
        backgroundColor: "#20B6A6",
        borderColor: "#20B6A6",
        data: [
          this.schoolSpendingChangeOvertime[1].amountI,
          this.schoolSpendingChangeOvertime[1].amountII,
          this.schoolSpendingChangeOvertime[1].amountIII,
        ],
        pointStyle: "circle",
        pointRadius: 7,
        pointHoverRadius: 10,
      });
    }
    if (
      !(
        this.schoolSpendingChangeOvertime[2].amountI == "" &&
        this.schoolSpendingChangeOvertime[2].amountII == "" &&
        this.schoolSpendingChangeOvertime[2].amountIII == ""
      )
    ) {
      LineDatas.push({
        label: "State",
        backgroundColor: "#808080",
        borderColor: "#808080",
        data: [
          this.schoolSpendingChangeOvertime[2].amountI,
          this.schoolSpendingChangeOvertime[2].amountII,
          this.schoolSpendingChangeOvertime[2].amountIII,
        ],
        pointStyle: "rect",
        pointRadius: 7,
        pointHoverRadius: 10,
      });
    }
    drawLineChart("lineChart", Linelabels, LineDatas, true);
  }

  get sidebarWidth(): string {
    let result = "";
    if (this.mobileView) {
      result = "100%";
    } else if (this.tabletView) result = "40%";
    return result;
  }

  calcCharIndex(index: any) {
    return String.fromCharCode(index + 1 + 64);
  }
  numberWithCommas(x: string): string {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}
