
import { Vue, Component } from "vue-property-decorator";
import { mapState } from "vuex";
import { CovidDashboard, K12School } from "@/models/interface";
import InfoModal from "@/components/InfoModal.vue";
import { drawPieChart, PieChartColors } from "@/assets/js/pie-chart";
import { drawLineChart } from "@/assets/js/line-chart";
@Component({
  components: {
    InfoModal,
  },
  computed: { ...mapState("app", ["school", "mobileView", "tabletView"]) },
  metaInfo: {
    title: "Spending Page",
  },
})
export default class DistrictCOVID19Impact extends Vue {
  givenTitle = "";
  givenDescription = "";
  mobileView!: boolean;
  tabletView!: boolean;

  school!: K12School;
  showItemDetail = false;
  dataList = [];
  public pielabelObj: string[] = [];
  public PieDataObj: (number | string)[] = [];
  private PieDataNaNCount = 0;
  public LinelabelObj: string[] = [];
  public LineDataObj: number[] = [];
  pieChartColors = PieChartColors;
  selectedHeaderText = "";
  public currentYear = new Date().getFullYear();
  public LinelabelsCovidAttendanceRate: number[] = [
    this.currentYear - 2,
    this.currentYear - 1,
    this.currentYear,
  ];
  public LineDatasCovidAttendanceRate: any = [];

  public pointShape = ["circle", "rec"];
  public lineChartForAttendanceData: unknown[] = [];
  public schoolLabels = ["School System", "State"];
  public masteryBasicLabels = ["basic", "mastery"];

  public lineChartForGraduationData: unknown[] | any = [];

  public lineChartForLeapData: unknown[] | any = [];
  public lineChartForMasteryData: unknown[] | any = [];
  public linecolor: string[] = [];
  
  public lineChartForLeapDataHighSchool: unknown[] | any = [];
  public lineChartForMasteryDataHighSchool: unknown[] | any = [];

  get schoolcovidDashboard(): CovidDashboard {
    console.log("schoolcovidDashboard", this.school?.covidDashboard);
    return this.school && this.school?.covidDashboard;
  }

  get schoolCovidDashboardScore(): string {
    if (this.schoolcovidDashboard?.simulatedSPS?.score) {
      return `C19 - ${this.schoolcovidDashboard.simulatedSPS.score}`;
    }
    return "N/A";
  }

  get schoolSpending() {
    console.log(this.school.schoolSpending);
    return this.school && this.school.schoolSpending;
  }
  get studentExpenditure() {
    return (
      this.school &&
      this.school.schoolSpending &&
      this.school.schoolSpending.studentExpenditure
    );
  }
  showInfo(showModal: string) {
    switch (showModal) {
      case "SchoolPerformanceScore":
        (this.givenTitle =
          "Simulated School System Performance Score for the year 2021"),
          (this.givenDescription =
            "This School System Performance was simulated by using Skip-Year Growth and is intended for information purposes only. To view the most recent accountability data, visit the Academic Performance Profile.");
        break;
      case "GraduationRate":
        (this.givenTitle = "What is the graduation rate?"),
          (this.givenDescription =
            "This indicator measures the percentage of students graduating within four years with a high school diploma. This indicator lags one year so the year that is reported equates to the previous year’s graduating class. For Example, a “2016-2017” graduation rate measures the rate for the class of 2016.");
        break;
      case "StudentsLearning":
        (this.givenTitle = "How were students learning in 2020 - 2021?"),
          (this.givenDescription =
            "Students were required to indicate whether students attended classes in school, online, or in a hybrid setting on their end of year assessments. This Data shows where students learned for the majority of the year 2021.                               ");
        break;
      default:
        (this.givenTitle = ""), (this.givenDescription = "");
        break;
    }

    //this.$bvModal.show("informationModal");
  }

  get schoolLevelSpending() {
    if (!this.schoolSpending) return [];
    return [
      {
        id: 1,
        type: "State/Local",
        amount: this.schoolSpending.siteExpenditureState,
      },
      {
        id: 2,
        type: "Federal",
        amount: this.schoolSpending.siteExpenditureFederal,
      },
      {
        id: 3,
        type: "Total",
        amount: this.schoolSpending.siteExpenditureTotal,
        percent: this.schoolSpending.siteExpenditureTotalPercent,
      },
    ];
  }
  get schoolSpendingCompare() {
    if (!this.schoolSpending) return [];
    return [
      {
        id: 1,
        type: "School",
        amount: this.schoolSpending.schoolSpendingOverTime[0].schoolResult,
      },
      {
        id: 2,
        type: "School System",
        amount: this.schoolSpending.schoolSpendingOverTime[0].schoolSystem,
      },
      {
        id: 3,
        type: "State",
        amount: this.schoolSpending.schoolSpendingOverTime[0].stateResult,
      },
    ];
  }
  get schoolSpendingChangeOvertime() {
    if (!this.schoolSpending) return [];
    return [
      {
        id: 1,
        type: "",
        yearI: this.schoolSpending.schoolSpendingOverTime[2].year,
        yearII: this.schoolSpending.schoolSpendingOverTime[1].year,
        yearIII: this.schoolSpending.schoolSpendingOverTime[0].year,
      },
      {
        id: 2,
        type: "School",
        amountI: this.schoolSpending.schoolSpendingOverTime[2].schoolResult,
        amountII: this.schoolSpending.schoolSpendingOverTime[1].schoolResult,
        amountIII: this.schoolSpending.schoolSpendingOverTime[0].schoolResult,
      },
      {
        id: 3,
        type: "School System",
        amountI: this.schoolSpending.schoolSpendingOverTime[2].schoolSystem,
        amountII: this.schoolSpending.schoolSpendingOverTime[1].schoolSystem,
        amountIII: this.schoolSpending.schoolSpendingOverTime[0].schoolSystem,
      },
      {
        id: 4,
        type: "State",
        amountI: this.schoolSpending.schoolSpendingOverTime[2].stateResult,
        amountII: this.schoolSpending.schoolSpendingOverTime[1].stateResult,
        amountIII: this.schoolSpending.schoolSpendingOverTime[0].stateResult,
      },
    ];
  }
  get covidAttedanceRate() {
    if (!this.schoolSpending) return [];
    return [
      {
        id: 1,
        type: "",
        yearI: this.LinelabelsCovidAttendanceRate[2],
        yearII: this.LinelabelsCovidAttendanceRate[1],
        yearIII: this.LinelabelsCovidAttendanceRate[0],
      },
      {
        id: 2,
        type: "School",
        amountI: this.schoolcovidDashboard.schoolAttendanceRate[2],
        amountII: this.schoolcovidDashboard.schoolAttendanceRate[1],
        amountIII: this.schoolcovidDashboard.schoolAttendanceRate[0],
      },
      {
        id: 3,
        type: "School System",
        amountI: this.schoolcovidDashboard.schoolSystemAttendanceRate[2],
        amountII: this.schoolcovidDashboard.schoolSystemAttendanceRate[1],
        amountIII: this.schoolcovidDashboard.schoolSystemAttendanceRate[0],
      },
      {
        id: 4,
        type: "State",
        amountI: this.schoolcovidDashboard.stateAttendanceRate[2],
        amountII: this.schoolcovidDashboard.stateAttendanceRate[1],
        amountIII: this.schoolcovidDashboard.stateAttendanceRate[0],
      },
    ];
  }
  get centralOfficeSpending() {
    if (!this.schoolSpending) return [];
    return [
      {
        id: 1,
        type: "State/Local",
        amount: this.schoolSpending.centralExpenditureState,
      },
      {
        id: 2,
        type: "Federal",
        amount: this.schoolSpending.centralExpenditureFederal,
      },
      {
        id: 3,
        type: "Total",
        amount: this.schoolSpending.centralExpenditureTotal,
        percent: this.schoolSpending.centralExpenditureTotalPercent,
      },
      {
        id: 4,
        type: "Per Student Spending",
        amount: this.school.schoolSpending.studentExpenditure,
        percent: "100%",
      },
    ];
  }

  mounted() {
    this.linecolor.push("#493698", "#20B6A6", "#808080", "#385463");

    if (this.schoolcovidDashboard) {
      this.lineChartForAttendanceData = [
       // this.schoolcovidDashboard.schoolAttendanceRate,
        this.schoolcovidDashboard.schoolSystemAttendanceRate,
        this.schoolcovidDashboard.stateAttendanceRate,
      ];
       setTimeout(() => {
      this.generatelinechart(
        "lineChartForAttendance",
        this.schoolLabels,
        this.lineChartForAttendanceData,
        this.pointShape,
        this.schoolcovidDashboard.attendanceRateYear
      );
      },200);

      this.lineChartForGraduationData = [
      //  this.schoolcovidDashboard.schoolCohortGraduationRate,
        this.schoolcovidDashboard.schoolSystemCohortGraduationRate,
        this.schoolcovidDashboard.stateCohortGraduationRate,
      ];
      setTimeout(() => {
        this.generatelinechart(
          "lineChartForGraduation",
          this.schoolLabels,
          this.lineChartForGraduationData,
          this.pointShape,
          this.schoolcovidDashboard.cohortGraduationRateYear
        );
      }, 1000);
      this.lineChartForLeapData = [
      //  this.schoolcovidDashboard.schoolLEAPBasic,
        this.schoolcovidDashboard.schoolSystemLEAPBasic,
        this.schoolcovidDashboard.stateLEAPBasic,
      ];

      setTimeout(() => {
      this.generatelinechart(
        "lineChartForLeap",
        ["School System Leap", "State Leap"],
        this.lineChartForLeapData,
        ["circle", "rec"],
        this.schoolcovidDashboard.leapBasicYear
      );
       },500);

      this.lineChartForMasteryData = [
        // this.schoolcovidDashboard.schoolLEAPMastery,
        this.schoolcovidDashboard.schoolSystemLEAPMastery,
        this.schoolcovidDashboard.stateLEAPMastery,
      ];
       setTimeout(() => {
      this.generatelinechart(
        "lineChartForMastery",
        ["School System Mastery", "State Mastery"],
        this.lineChartForMasteryData,
        ["circle", "rec"],
        this.schoolcovidDashboard.leapMasteryYear
      );
       },400);
       this.lineChartForLeapDataHighSchool = [
        // this.schoolcovidDashboard.schoolLEAPBasicHighSchool,
        this.schoolcovidDashboard.schoolSystemLEAPBasicHighSchool,
        this.schoolcovidDashboard.stateLEAPBasicHighSchool,
      ];
       setTimeout(() => {
      this.generatelinechart(
        "lineChartForLeapHighSchool",
        ["School System Leap", "State Leap"],
        this.lineChartForLeapDataHighSchool,
        ["circle", "rec"],
        this.schoolcovidDashboard.leapBasicYearHighSchool
      );},500);

      this.lineChartForMasteryDataHighSchool = [
      //  this.schoolcovidDashboard.schoolLEAPMasteryHighSchool,
        this.schoolcovidDashboard.schoolSystemLEAPMasteryHighSchool,
        this.schoolcovidDashboard.stateLEAPMasteryHighSchool,
      ];
       setTimeout(() => {
      this.generatelinechart(
        "lineChartForMasteryHighSchool",
        ["School System Mastery", "State Mastery"],
        this.lineChartForMasteryDataHighSchool,
        ["circle", "rec"],
        this.schoolcovidDashboard.leapMasteryYearHighSchool
      );},600);

      this.generatepiechart();
    }
  }

  generatelinechart(
    domId: string,
    chartLabels: string[],
    dataPoints: unknown[],
    pointStyles: string[],
    lineLabels: string[]
  ) {
    const lineData: any = [];
    chartLabels.forEach((item, index) => {
      lineData.push({
        label: item,
        backgroundColor: this.linecolor[index],
        borderColor: this.linecolor[index],
        data: dataPoints[index],
        pointStyle: pointStyles[index],
        pointRadius: 7,
        pointHoverRadius: 10,
      });
    });
    drawLineChart(domId, lineLabels, lineData, true);
  }

  generatepiechart() {
    this.PieDataNaNCount = 0;
    if (this.schoolcovidDashboard) {
      const Pielabels = this.schoolcovidDashboard.modalityOfLearningLabel;
      const PieData = this.schoolcovidDashboard.modalityOfLearningDataPoint.map(
        (item) => parseInt(item)
      );
      this.pielabelObj = Pielabels;
      this.PieDataObj = PieData;
      drawPieChart("pieChart", Pielabels, PieData);
    }
  }

  get studentLearnCount() {
    console.log("PieDataObj", this.PieDataObj);
    return this.PieDataObj.filter((x) => x + "" === "NaN").length >= 4;
  }

  get isAvailablelineChartForGraduationDataSchool() {
    console.log("lineChartForGraduationData", this.lineChartForGraduationData);
    return this.lineChartForGraduationData.length > 0 &&
      this.lineChartForGraduationData[0].filter((x: any) => x + "" != "")
        .length < 1
      ? false
      : true;
  }

  get isAvailablelineChartForBasicHighSchool() {
    console.log("lineChartForLeapDataHighSchool", this.lineChartForLeapDataHighSchool);
    return this.lineChartForLeapDataHighSchool.length > 0 &&
      this.lineChartForLeapDataHighSchool[0].filter((x: any) => x + "" != "")
        .length < 1
      ? false
      : true;
  }

 get isAvailablelineChartForBasic() {
    console.log("lineChartForLeapData", this.lineChartForLeapData);
    return this.lineChartForLeapData.length > 0 &&
      this.lineChartForLeapData[0].filter((x: any) => x + "" != "")
        .length < 1
      ? false
      : true;
  }

 get isAvailablelineChartForMasteryHighSchool() {
    console.log("lineChartForMasteryDataHighSchool", this.lineChartForMasteryDataHighSchool);
    return this.lineChartForMasteryDataHighSchool.length > 0 &&
      this.lineChartForMasteryDataHighSchool[0].filter((x: any) => x + "" != "")
        .length < 1
      ? false
      : true;
  }
 get isAvailablelineChartForMastery() {
    console.log("lineChartForMasteryData", this.lineChartForMasteryData);
    return this.lineChartForMasteryData.length > 0 &&
      this.lineChartForMasteryData[0].filter((x: any) => x + "" != "")
        .length < 1
      ? false
      : true;
  }
  get spendingInstructionBarChartData() {
    if (!this.schoolSpending) return [];

    const data = this.schoolSpending.schoolSpendingByTypeBarChartData.find(
      (t) => t.spendingTypeName == "Instruction"
    );
    console.log(data);

    return {
      schoolNameAverage: data?.schoolNameAverage,
      schoolSystemAverage: data?.schoolSystemAverage,
      stateAverage: data?.stateAverage,
    };
  }

  get spendingInstructionalSupportBarChartData() {
    if (!this.schoolSpending) return [];

    const data = this.schoolSpending.schoolSpendingByTypeBarChartData.find(
      (t) => t.spendingTypeName == "Student & Instructional Support"
    );

    return {
      schoolNameAverage: data?.schoolNameAverage,
      schoolSystemAverage: data?.schoolSystemAverage,
      stateAverage: data?.stateAverage,
    };
  }

  get spendingAdministrationBarChartData() {
    if (!this.schoolSpending) return [];

    const data = this.schoolSpending.schoolSpendingByTypeBarChartData.find(
      (t) => t.spendingTypeName == "School Administration"
    );

    return {
      schoolNameAverage: data?.schoolNameAverage,
      schoolSystemAverage: data?.schoolSystemAverage,
      stateAverage: data?.stateAverage,
    };
  }

  get spendingTransportationBarChartData() {
    if (!this.schoolSpending) return [];

    const data = this.schoolSpending.schoolSpendingByTypeBarChartData.find(
      (t) => t.spendingTypeName == "Transportation"
    );

    return {
      schoolNameAverage: data?.schoolNameAverage,
      schoolSystemAverage: data?.schoolSystemAverage,
      stateAverage: data?.stateAverage,
    };
  }

  get spendingOtherSupportBarChartData() {
    if (!this.schoolSpending) return [];

    const data = this.schoolSpending.schoolSpendingByTypeBarChartData.find(
      (t) => t.spendingTypeName == "Other Support"
    );

    return {
      schoolNameAverage: data?.schoolNameAverage,
      schoolSystemAverage: data?.schoolSystemAverage,
      stateAverage: data?.stateAverage,
    };
  }

  get sidebarWidth(): string {
    let result = "";
    if (this.mobileView) {
      result = "100%";
    } else if (this.tabletView) result = "40%";
    return result;
  }

  calcCharIndex(index: any) {
    return String.fromCharCode(index + 1 + 64);
  }
  numberWithCommas(x: string): string {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  verifyNumber(x: string): string {
    if (!isNaN(Number(x))) {
      return "%";
    } else {
      return "";
    }
  }
}
